* {
  margin: 0;
  padding: 0
}

@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Sofia+Sans+Extra+Condensed:ital,wght@0,1..1000;1,1..1000&display=swap');

h1,
h2,
h3,
h4,
h5 {
  font-family: "Sofia Sans Extra Condensed", "sans-serif";
  font-weight: 400;
  font-style: normal;
}

.imgCardGallery {
  display: inline-block;
  background-repeat: no-repeat;
  background-position: center;
  object-fit: cover;
  height: 35vh;
  width: 15vw;
  min-width: 22.8rem;
}

.miniBanner {
  position: absolute;
  height: 50vh;
}

.albercaBanner {
  position: 100%;
  width: 50vh;
}


.contactoBanner {
  position: absolute;
  height: 85vh;
}

.sbt {
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: large;
}

.minSbt {
  font-family: "Poppins", sans-serif;
  font-weight: 200;
  font-style: normal;
  font-size: medium;
}

.lightTitle {
  font-family: "Sofia Sans Extra Condensed", sans-serif;
  font-weight: 350;
  letter-spacing: 0.1rem;
  font-style: normal;

}
.radiosDown{
  border-radius: 0px 30px 30px 0px;
}
.radiosUp{
  border-radius: 0px 30px 0px 0px;
}
.transBck{
  background-color: rgba(0, 0, 0, 0.886);
}
.pharagrap {
  letter-spacing: .18rem;
}

.bg-pool {
  background-color: #064789;
}

.bg-grass {
  background-color: #63b32e;
}

.banner-image {
  background-repeat: no-repeat;
  background-position: center;
  object-fit: cover;
}

.homeBanner {
  height: 75vh
}

.innerBackground {
  background-color: #f5f5f5;
}

.ngi-dark-color {
  background-color: rgb(26, 26, 37);
}

.nav-option {
  text-align: center;
}

.bg-ts-black {
  background-color: rgba(0, 0, 0, 0.733);
}

.v-carrousel {
  overflow-x: scroll;
}

.bannerLayout {
  background-color: rgba(0, 0, 0, 0.219);
}

.primaryBackground {
  background-color: #01080f;
}

.secondaryBackground {
  background-color: #0c0d0e;
}

.v-carrousel-items {
  display: inline-block;
  border-radius: 0;
}

.zoom-in-out-box {
  width: 50px;
  height: 50px;
  background: #004ef5;
  animation: zoom-in-zoom-out 45s ease infinite;
}

@keyframes zoom-in-zoom-out {
  0% {
    transform: scale(1.0, 1.0);
  }

  50% {
    transform: scale(1.3, 1.3);
  }

  100% {
    transform: scale(1.6, 1.6);
  }
}